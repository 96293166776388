var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('otium-base-page',{scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [_c('h1',{staticClass:"mb-5"},[_vm._v("Administration - Users")]),_c('p',[_vm._v("You can manage the Otium wide member permissions here.")])]},proxy:true},{key:"content",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('h2',[_vm._v("Template editors")]),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.templateCreatorUsers,"items-per-page":-1,"hide-default-footer":"","no-data-text":"There are currently no template editors."},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"tile":"","elevation":"0"},on:{"click":function($event){return _vm.removeFromTemplateEditors(item.userId)}}},[_vm._v(" Remove ")])]}}])}),_c('v-divider',{staticClass:"my-8"}),_c('h2',[_vm._v("Available Users")]),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredUsers,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"tile":"","elevation":"0"},on:{"click":function($event){return _vm.addToTemplateEditors(item.userId)}}},[_vm._v(" Add to template editors ")])]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }