












































import { defineComponent, ref, computed, watch, onMounted, toRefs } from "@vue/composition-api";
import { AdminClient, AdminUserModel } from "@/api/OtiumAppApi";
import OtiumBasePage from "../OtiumBasePage.vue";

export default defineComponent({
  name: "AdminUsers",
  components: {
    OtiumBasePage
  },
  props: {},
  setup(props, { root, refs }) {
    const adminClient = new AdminClient();

    const allUsers = ref<Array<AdminUserModel>>([]);
    const templateCreatorUsers = ref<Array<AdminUserModel>>([]);

    async function loadUsers() {
      [allUsers.value, templateCreatorUsers.value] = await Promise.all([
        adminClient.getAllUsers(),
        adminClient.getUsersInTemplateCreatorRole()
      ]);
    }

    onMounted(loadUsers);

    async function addToTemplateEditors(userId: string) {
      await adminClient.addUserToTemplateCreatorRole(userId);
      await loadUsers();
    }

    async function removeFromTemplateEditors(userId: string) {
      await adminClient.removeUserFromTemplateCreatorRole(userId);
      await loadUsers();
    }

    const filteredUsers = computed(() => {
      const map = templateCreatorUsers.value.map((x) => x.userId);
      return allUsers.value.filter((u) => !map.includes(u.userId));
    });

    const tableHeaders = [
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "", value: "actions", align: "end", sortable: false }
    ];

    return {
      allUsers,
      filteredUsers,
      templateCreatorUsers,
      addToTemplateEditors,
      removeFromTemplateEditors,
      tableHeaders
    };
  }
});
